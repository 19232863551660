<template>
  <div>
    <b-img src="@/assets/images/logo/AMERA.png" height="80"></b-img>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>
